import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  pinnedAddress = '';

  appPlatform = 'web';
  appVersion = '0.21';


  geoLocatorRequest = {
    title : '',
    returnURL : '',
  };

  geoLocatorResponse: any;

  constructor(
    private title: Title,
    private platform: Platform
  ) {

    if (this.platform.is('android')){
      this.appPlatform = 'android';
    }

    if (this.platform.is('ios')){
      this.appPlatform = 'ios';
    }

  }


  setTitle(title){
    this.title.setTitle(title + ' - PawMeetUp');
  }
  getLastTwoWords(value: string): string {
    if (!value) { return ''; }

    // console.log('value.length',value.length);
    const address = value.split(', ')[value.split(', ').length - 4] + ', ' + value.split(', ')[value.split(', ').length - 3];
    return address + ', ' + value.split(', ')[value.split(', ').length - 2];
  }

  getFirstName(value: string): string {
    if (!value) { return ''; }
    return value.split(' ')[0];
  }
}

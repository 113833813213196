import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root'
})

export class MerchantAvailableTypeService {

  merchantAvailableType: any = null;

  featuredMerchantAvailableTypes: any[] = [];
  featuredMerchantAvailableTypesNextPageLink: string = null;

  merchantAvailableTypes: any[] = [];
  merchantAvailableTypesNextPageLink: string = null;

  merchantAvailableTypesCreatePayload: any;

  constructor(
    private http: HttpClient,
    private env: EnvService
  ) { }

  getMerchantAvailableTypes(keyword = ''): Observable<any> {


    return this.http.get<any>(this.env.apiURL + 'merchant-available-types?keyword=' + keyword)
      .pipe(
        tap(response => {
          this.merchantAvailableTypes = response.data;
          this.merchantAvailableTypesNextPageLink = response.data.next_page_url;
        }),
        retry(2),
        catchError(this.handleError)
      );
  }

  getMerchantAvailableType(id): Observable<any> {


    return this.http.get<any>(this.env.apiURL + 'merchant-available-types/' + id)
      .pipe(
        tap(response => {
          this.merchantAvailableType = response.data;
          console.log(this.merchantAvailableType);
        }),
        retry(2),
        catchError(this.handleError)
      );

  }

  addMerchantAvailableType(formData: FormData) {
    const headers = new HttpHeaders({
      // 'Authorization': "Bearer " + this.authService.token,
    });

    return this.http.post<any>(this.env.apiURL + 'merchants',formData,{ headers })
    .pipe(
      tap(response => {
        // this.missingPet = response.missing_pet;
        // this.missingPetID = response.missing_pet_id;
        // console.log(this.missingPet);
      }),
      retry(2),
      catchError(this.handleError)
    );

  }

  getFeaturedMerchantAvailableTypes(): Observable<any> {


    return this.http.get<any>(this.env.apiURL + 'merchants')
      .pipe(
        tap(response => {
          this.featuredMerchantAvailableTypes = response.data.data;
          this.featuredMerchantAvailableTypesNextPageLink = response.data.next_page_url;
        }),
        retry(2),
        catchError(this.handleError)
      );
  }

  getMerchantAvailableTypesNextPage(): Observable<any> {

    return this.http.get<any>(this.merchantAvailableTypesNextPageLink)
      .pipe(
        tap(response => {
          this.merchantAvailableTypes = this.merchantAvailableTypes.concat(response.data.data);
          this.merchantAvailableTypesNextPageLink = response.data.next_page_url;
        }),
        retry(2),
        catchError(this.handleError)
      );
  }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };


}

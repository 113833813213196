<ion-app>
  <ion-split-pane contentId="my-content">
    
    
    <ion-menu menuId="my-content" contentId="my-content" side="end" class="my-custom-menu">
      
      <ion-header id="side-navigation" class="ion-no-border"  *ngIf="authService.userData && authService.isLoggedIn">
        
        <ion-toolbar color="primary" class="ion-text-center">
          <div class="profile-container">
            <div class="thumbnail"><ion-img src="assets/images/user.png"></ion-img></div>
            <p class="name">{{ globalService.getFirstName(authService.userData.name) }}</p>
          </div>
          <div class="points-container">
            <span>You Got <span class="points">{{ authService.userData.reward_coins }}</span> Paw Coins</span>
          </div>
        </ion-toolbar>

      </ion-header>

      <ion-header id="side-navigation" class="ion-no-border"  *ngIf="!authService.isLoggedIn">
        
        <ion-toolbar color="primary" class="ion-text-center">
          <ion-title>MENU</ion-title>
        </ion-toolbar>
        
      </ion-header>
      
      <ion-content>

        

        <ion-list>
          
          <ion-menu-toggle auto-hide="false">

            <ion-item [routerLink]="['']">
              <ion-label><ion-icon name="home-outline"></ion-icon>Home</ion-label>
            </ion-item>

            <ion-item *ngIf="authService.isLoggedIn" [routerLink]="['/user-profile']">
              <ion-label><ion-icon name="person-circle-outline"></ion-icon>My Profile</ion-label>
            </ion-item>

            <ion-item *ngIf="authService.isLoggedIn" [routerLink]="['/earn-paws']">
              <ion-label><ion-icon name="paw-outline"></ion-icon>Earn Paw Coins</ion-label>
            </ion-item>

            <ion-item *ngIf="authService.isLoggedIn" [routerLink]="['/my-paw-coins']">
              <ion-label><ion-icon name="gift-outline"></ion-icon>My Paw Coins</ion-label>
            </ion-item>

            <!-- <ion-item [routerLink]="['/missing-pets']">
              <ion-label><ion-icon name="alert-circle-outline"></ion-icon>Found/Missing Pets</ion-label>
            </ion-item>

            <ion-item [routerLink]="['/adopt-pets']">
              <ion-label><ion-icon name="heart-circle-outline"></ion-icon>Adopt-a-Pet</ion-label>
            </ion-item>

            <ion-item [routerLink]="['/explore']">
              <ion-label><ion-icon name="map-outline"></ion-icon>Explore</ion-label>
            </ion-item>
             -->
          


            <ion-item-group>
              
              <!-- <ion-item-divider>
                <ion-label>Account</ion-label>
              </ion-item-divider> -->

              <ion-item *ngIf="!authService.isLoggedIn" [routerDirection]="'root'" [routerLink]="['/login']">
                <ion-label>
                  <ion-icon name="key-outline"></ion-icon> Hello, Sign in?
                </ion-label>
              </ion-item>
  
          
              <ion-item *ngIf="!authService.isLoggedIn" [routerDirection]="'root'" [routerLink]="['/register']">
                <ion-label>
                  <ion-icon name="log-out-outline"></ion-icon> Create an Account
                </ion-label>
              </ion-item>

              
              <ion-item [routerLink]="['/about']">
                <ion-label><ion-icon name="information-circle-outline"></ion-icon>About</ion-label>
              </ion-item>

              <ion-item [routerLink]="['/contact-us']">
                <ion-label><ion-icon name="mail-outline"></ion-icon>Contact Us</ion-label>
              </ion-item>

              <ion-item *ngIf="authService.isLoggedIn" (click)="logout()">
                <ion-label><ion-icon name="log-out-outline"></ion-icon>Logout</ion-label>
              </ion-item>

              
             
              <!-- <ion-item (click)="logout()">
                <ion-label>For Veterinary</ion-label>
              </ion-item>
              <ion-item (click)="logout()">
                <ion-label>Advertise with US</ion-label>
              </ion-item>
              <ion-item (click)="logout()">
                <ion-label>Contact Us</ion-label>
              </ion-item> -->

            </ion-item-group>

            
          </ion-menu-toggle>

         
        </ion-list>

        
      </ion-content>
      <ion-footer>
        <ion-toolbar color="primary">
          <div class="ion-padding">
            <small>PawMeetUp Mobile App
              <span *ngIf="globalService.appVersion">v{{ globalService.appVersion }} </span> 
              <span *ngIf="globalService.appPlatform">({{ globalService.appPlatform }})</span>
            </small>
          </div>
        </ion-toolbar>
      </ion-footer>
    </ion-menu>

    <ion-router-outlet id="my-content"></ion-router-outlet>
    

  </ion-split-pane>

  
</ion-app>

import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { StatusBar, Style } from '@capacitor/status-bar';
import { AlertController, Platform } from '@ionic/angular';
import { AlertService } from './services/alert.service';
import { AuthService } from './services/auth.service';
import { GlobalService } from './services/global.service';
import { MerchantAvailableTypeService } from './services/merchant-available-type.service';
import OneSignal from 'onesignal-cordova-plugin';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private zone: NgZone,
    private platform: Platform,
    private router: Router,
    private alertService: AlertService,
    public authService: AuthService,
    public globalService: GlobalService,
    public merchantAvailableTypeService: MerchantAvailableTypeService
  ) {

    // Check token and get user
    if (this.authService.token){
      this.authService.user().subscribe();
    }

    // Check Location
    if (localStorage.getItem('location')){
      this.globalService.geoLocatorResponse = JSON.parse(localStorage.getItem('location'));
    }

    if (this.platform.is('mobile') && this.platform.is('cordova')){
      const statusBarBackgroundColorOptions = {
        color : '#C36F09'
      };

      StatusBar.setStyle({ style: Style.Dark });
      StatusBar.setBackgroundColor(statusBarBackgroundColorOptions);

    }


    // Getting Merchant Available Types
    this.merchantAvailableTypeService.getMerchantAvailableTypes().subscribe();

    this.platform.ready().then(() => {

      if (this.platform.is('hybrid')){
        this.oneSignalInit();
      }

    });

    this.initializeApp();

  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        this.zone.run(() => {
            // Example url: https://beerswift.app/tabs/tab2
            // slug = /tabs/tab2
            const slug = event.url.split('.com').pop();
            if (slug) {
                this.router.navigateByUrl(slug);
            }
            // If no match, do nothing - let regular routing
            // logic take over
        });
    });
}

  // Call this function when your app starts
  oneSignalInit(){
    // Uncomment to set OneSignal device logging to VERBOSE
    // OneSignal.setLogLevel(6, 0);

    // NOTE: Update the setAppId value below with your OneSignal AppId.
    OneSignal.setAppId('cf222a70-c179-4e6e-bb74-daf7f36526db');
    OneSignal.setNotificationOpenedHandler(jsonData => {
        console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
    });

    const router = this.router;
    OneSignal.setNotificationOpenedHandler(jsonData => {
      const additionalData = jsonData.notification.additionalData;
      // eslint-disable-next-line @typescript-eslint/dot-notation
      const action = additionalData['action'];
      if (action === 'navigate'){
        // eslint-disable-next-line @typescript-eslint/dot-notation
        const url = additionalData['url'];
        router.navigateByUrl(url);
      }
      // alert('notificationOpenedCallback: ' + JSON.stringify(jsonData));
      // alert('notificationOpenedCallback: ' + JSON.stringify(additionalData));
    });

    // Prompts the user for notification permissions.
    OneSignal.promptForPushNotificationsWithUserResponse(accepted => {
        console.log('User accepted notifications: ' + accepted);
    });
  }

  logout(){
    this.authService.logout();
    this.alertService.presentToast('Successfully logout','success');
    this.router.navigateByUrl('/');
  }
}

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry, catchError, tap } from 'rxjs/operators';
import { EnvService } from './env.service';
import { StorageService } from './storage.service';
import { GlobalService } from './global.service';
import { computeStackId } from '@ionic/angular/directives/navigation/stack-utils';
import { NgForm } from '@angular/forms';
import { AlertService } from './alert.service';
import { Platform } from '@ionic/angular';
import { Observable, throwError } from 'rxjs';
import OneSignal from 'onesignal-cordova-plugin';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  token: any;
  isLoggedIn = false;
  userData: any = null;
  followers: any;
  // role = 'subscriber';

  userID: number;
  userEmail: string;
  isStoreManager = false;
  storeID: number;
  userRole = 'subscriber';

  deliveryRiders: any = [];
  userRewards: any = [];

  constructor(
    private platform: Platform,
    private http: HttpClient,
    private storageService: StorageService,
    public globalService: GlobalService,
    private env: EnvService,
    private alertService: AlertService
  ) {

    this.token = localStorage.getItem('token');
  }


  user(){

    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization : 'Bearer ' + this.token
    });

    let params = '';
    params+='appPlatform=' + this.globalService.appPlatform;

    // Check Location
    if (localStorage.getItem('location')){
      this.globalService.geoLocatorResponse = JSON.parse(localStorage.getItem('location'));

      if (this.globalService.geoLocatorResponse &&  this.globalService.geoLocatorResponse.latitude){
        params+='&latitude='+ this.globalService.geoLocatorResponse.latitude;
        params+='&longitude='+ this.globalService.geoLocatorResponse.longitude;
        params+='&plus_code='+ this.globalService.geoLocatorResponse.address;
      }
    }


    return this.http.get<any>(this.env.apiURL + 'user?' + params, { headers })
    .pipe(
      tap(user => {
        console.log('user',user);
        this.isLoggedIn = true;
        this.userData = user;
        this.userID = user.id;
        this.userEmail = user.email;
        this.userRole = user.role;
        this.followers = user.followers;

        if (this.platform.is('hybrid')){
          OneSignal.setExternalUserId(this.userID.toString());
        }

        return user;
      })
    );

  }

  rewards(){

    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization : 'Bearer ' + this.token
    });
    return this.http.get<any>(this.env.apiURL + 'user-rewards', { headers })
    .pipe(
      tap(response => {
        this.userRewards = response.data.data;
      })
    );

  }

  login(email: string, password: string) {
    return this.http.post<any>(this.env.apiURL + 'auth/login',{email, password}
    ).pipe(
      tap(result => {

        // Set token
        localStorage.setItem('token',result.token);
        this.token = result.token;

        // Authenticate
        this.user().subscribe();

      }),
    );
  }

  update(payload) {

    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization : 'Bearer ' + this.token
    });
    return this.http.post<any>(this.env.apiURL + 'auth/update',payload, { headers })
    .pipe(
      tap(result => {

        // Authenticate
        this.user().subscribe();

      }),
    );
  }

  register(name: string, email: string, password: string, confirmPassword: string, referralCode: string = '') {

    const payload = {name, email, password, confirmPassword, referralCode};
    return this.http.post<any>(this.env.apiURL + 'auth/register',payload,{})
    .pipe(
      tap(result => {

        if (result.status){

          // Set token
          localStorage.setItem('token',result.token);
          this.token = result.token;

          // Authenticate
          this.user().subscribe();

        }
        return result;
      }),
      retry(2),
      catchError(this.handleError)
    );
  }

  resetPassword(payload: any) {

    return this.http.post<any>(this.env.apiURL + 'auth/reset-password',payload,{})
    .pipe(
      tap(result => {
        //
      }),
      retry(2),
      catchError(this.handleError)
    );
  }

  deleteAccount(payload: any) {

    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization : 'Bearer ' + this.token
    });
    return this.http.post<any>(this.env.apiURL + 'auth/delete-account',payload,{headers})
    .pipe(
      tap(result => {
        this.logout();
      }),
      retry(2),
      catchError(this.handleError)
    );
  }

  sendEmail(payload: any) {

    return this.http.post<any>(this.env.apiURL + 'auth/send-email',payload,{})
    .pipe(
      tap(result => {
        //
      }),
      retry(2),
      catchError(this.handleError)
    );
  }

  logout() {

    localStorage.removeItem('token');
    this.isLoggedIn = false;
    this.userRole = 'subscriber';
    this.followers = [];
  }


  getDeliveryRiders(){

    console.log('getDeliveryRiders API');

    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization : 'Bearer ' + this.token
    });

    return this.http.get<any>(this.env.apiURL + 'delivery-riders', { headers })
    .pipe(
      tap(data => data)
    );
  }

  // getToken() {

  //   return this.storageService.get('token').then(
  //     data => {

  //       if (data){
  //         this.token = data;
  //         this.token = this.token;

  //         this.user().subscribe(
  //           user => {
  //             console.log('this.user()',user);
  //             this.userRole = user.user_role;

  //             // this.isLoggedIn=true;
  //             // this.userID = data.id;
  //             // this.userEmail = data.email;
  //             // this.alertService.presentToast('Welcome back ' + data.name + '!','success');
  //           },
  //           error => {
  //             // this.storageService.remove("token");
  //             this.isLoggedIn = false;
  //           },
  //           () => {
  //             // this.dismissLogin();
  //             // this.navCtrl.navigateRoot('/landing');
  //           }
  //         );
  //       }else{
  //         console.warn('No token found');
  //       }

  //     },
  //     error => {
  //       this.token = null;
  //       this.isLoggedIn=false;
  //     }
  //   );
  // }


  // updateUser(formData: FormData) {

  //   console.log('updateStore formData',formData);
  //   const headers = new HttpHeaders({
  //     // eslint-disable-next-line @typescript-eslint/naming-convention
  //     Authorization : 'Bearer ' + this.token
  //   });

  //   return this.http.post<any>(this.env.apiURL + 'user/update-user',formData,{ headers }).pipe(
  //     tap(user => {
  //       console.log('updateUser',user);
  //       return user;
  //     }),
  //     retry(2),
  //     catchError(this.handleError)
  //   );
  // }

  // updateUserLocation() {

  //   console.log('updateUserLocation');
  //   let payload = {};
  //   this.storageService.get('coordinates').then(
  //     data => {
  //       if (data){
  //         const coors = JSON.parse(data);

  //         console.log('coors',coors);
  //         const latitude = coors.latitude;
  //         const longitude = coors.longitude;
  //         const geolocation = coors.address;
  //         const address = coors.addressInformation;

  //         // alert(address);

  //         payload = {latitude,longitude,geolocation,address};
  //         // alert(JSON.stringify(payload));
  //         // alert(this.token);
  //         // this.user();
  //         this.runUpdateUser(payload).subscribe(runUpdateData => {
  //           console.log('runUpdateUser data',runUpdateData);
  //         });

  //       }
  //     }
  //   );
  // }

  // runUpdateUser(payload): Observable<any> {

  //   const headers = new HttpHeaders({
  //     // eslint-disable-next-line @typescript-eslint/naming-convention
  //     Authorization : 'Bearer ' + this.token
  //   });
  //         return this.http.post<any>(this.env.apiURL + 'user/update-location',payload,{ headers }).pipe(
  //           retry(2),
  //           catchError(this.handleError)
  //         );

  // }

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };

}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'explore',
    loadChildren: () => import('./pages/explore/explore.module').then( m => m.ExplorePageModule)
  },
  {
    path: 'explore/:id',
    loadChildren: () => import('./pages/business/business.module').then( m => m.BusinessPageModule)
  },
  {
    path: 'explore-category/:id',
    loadChildren: () => import('./pages/explore-category/explore-category.module').then( m => m.ExploreCategoryPageModule)
  },
  {
    path: 'rescue-centers',
    loadChildren: () => import('./pages/rescue-centers/rescue-centers.module').then( m => m.RescueCentersPageModule)
  },
  {
    path: 'rescue-center/:id',
    loadChildren: () => import('./pages/rescue-center/rescue-center.module').then( m => m.RescueCenterPageModule)
  },
  {
    path: 'missing-pets',
    loadChildren: () => import('./pages/missing-pets/missing-pets.module').then( m => m.MissingPetsPageModule)
  },
  {
    path: 'missing-pet/:id',
    loadChildren: () => import('./pages/missing-pet/missing-pet.module').then( m => m.MissingPetPageModule)
  },
  {
    path: 'missing-pet-create',
    loadChildren: () => import('./pages/missing-pet-create/missing-pet-create.module').then( m => m.MissingPetCreatePageModule)
  },
  {
    path: 'missing-pet-update/:id',
    loadChildren: () => import('./pages/missing-pet-update/missing-pet-update.module').then( m => m.MissingPetUpdatePageModule)
  },
  {
    path: 'adopt-pets',
    loadChildren: () => import('./pages/adopt-pets/adopt-pets.module').then( m => m.AdoptPetsPageModule)
  },
  {
    path: 'adopt-pet/:id',
    loadChildren: () => import('./pages/adopt-pet/adopt-pet.module').then( m => m.AdoptPetPageModule)
  },
  {
    path: 'adopt-pet-create',
    loadChildren: () => import('./pages/adopt-pet-create/adopt-pet-create.module').then( m => m.AdoptPetCreatePageModule)
  },
  {
    path: 'adopt-pet-update/:id',
    loadChildren: () => import('./pages/adopt-pet-update/adopt-pet-update.module').then( m => m.AdoptPetUpdatePageModule)
  },
  {
    path: 'pregnancy-calculators',
    loadChildren: () => import('./pages/pregnancy-calculators/pregnancy-calculators.module').then( m => m.PregnancyCalculatorsPageModule)
  },
  {
    path: 'geolocator',
    loadChildren: () => import('./pages/common/geolocator/geolocator.module').then( m => m.GeolocatorPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'explore-submit',
    loadChildren: () => import('./pages/explore-submit/explore-submit.module').then( m => m.ExploreSubmitPageModule)
  },
  {
    path: 'image-modal',
    loadChildren: () => import('./pages/image-modal/image-modal.module').then( m => m.ImageModalPageModule)
  },
  {
    path: 'explore-update/:id',
    loadChildren: () => import('./pages/explore-update/explore-update.module').then( m => m.ExploreUpdatePageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./pages/contact-us/contact-us.module').then( m => m.ContactUsPageModule)
  },
  {
    path: 'delete-account',
    loadChildren: () => import('./pages/delete-account/delete-account.module').then( m => m.DeleteAccountPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'explore-verified',
    loadChildren: () => import('./pages/explore-verified/explore-verified.module').then( m => m.ExploreVerifiedPageModule)
  },
  {
    path: 'user-profile',
    loadChildren: () => import('./pages/user-profile/user-profile.module').then( m => m.UserProfilePageModule)
  },
  {
    path: 'explore-sections/:id',
    loadChildren: () => import('./pages/explore-sections/explore-sections.module').then( m => m.ExploreSectionsPageModule)
  },
  {
    path: 'ask-pamu',
    loadChildren: () => import('./pages/ask-pamu/ask-pamu.module').then( m => m.AskPamuPageModule)
  },
  {
    path: 'earn-paws',
    loadChildren: () => import('./pages/earn-paws/earn-paws.module').then( m => m.EarnPawsPageModule)
  },
  {
    path: 'my-paw-coins',
    loadChildren: () => import('./pages/my-paw-coins/my-paw-coins.module').then( m => m.MyPawCoinsPageModule)
  },
  {
    path: 'my-pets/:id',
    loadChildren: () => import('./pages/my-pets/my-pets.module').then( m => m.MyPetsPageModule)
  },
  {
    path: 'my-pet/:id',
    loadChildren: () => import('./pages/my-pet/my-pet.module').then( m => m.MyPetPageModule)
  },
  {
    path: 'my-pets-create',
    loadChildren: () => import('./pages/my-pets-create/my-pets-create.module').then( m => m.MyPetsCreatePageModule)
  },
  {
    path: 'my-pets-update/:id',
    loadChildren: () => import('./pages/my-pets-update/my-pets-update.module').then( m => m.MyPetsUpdatePageModule)
  },
  {
    path: 'pamu-questions-answers',
    loadChildren: () => import('./pages/pamu-questions-answers/pamu-questions-answers.module').then( m => m.PamuQuestionsAnswersPageModule)
  },
  {
    path: 'pets',
    loadChildren: () => import('./pages/pets/pets.module').then( m => m.PetsPageModule)
  },
  {
    path: 'my-pet-records/:id',
    loadChildren: () => import('./pages/my-pet-records/my-pet-records.module').then( m => m.MyPetRecordsPageModule)
  },
  {
    path: 'my-pet-record/:id',
    loadChildren: () => import('./pages/my-pet-record/my-pet-record.module').then( m => m.MyPetRecordPageModule)
  },
  {
    path: 'my-pet-record-create/:id',
    loadChildren: () => import('./pages/my-pet-record-create/my-pet-record-create.module').then( m => m.MyPetRecordCreatePageModule)
  },
  {
    path: 'my-pet-record-update/:id',
    loadChildren: () => import('./pages/my-pet-record-update/my-pet-record-update.module').then( m => m.MyPetRecordUpdatePageModule)
  },
  {
    path: 'my-pet-travel-level',
    loadChildren: () => import('./pages/my-pet-travel-level/my-pet-travel-level.module').then( m => m.MyPetTravelLevelPageModule)
  },
  {
    path: 'my-pet-travel-level-create',
    loadChildren: () => import('./pages/my-pet-travel-level-create/my-pet-travel-level-create.module').then( m => m.MyPetTravelLevelCreatePageModule)
  },
  {
    path: 'missing-pet-poster',
    loadChildren: () => import('./pages/missing-pet-poster/missing-pet-poster.module').then( m => m.MissingPetPosterPageModule)
  },
  {
    path: 'frames',
    loadChildren: () => import('./pages/frames/frames.module').then( m => m.FramesPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
